.logo-image {
    /* margin: 14px 40px; */
    width: 25%;
  
}
.sidebar-wrapper {
    background-color: #12181a;
    border-right: 1px solid #e4e4e400;
    box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 32%) !important;
}


   li a {
    color: #9ea4aa;
    text-decoration: none;
    display: block; 
    padding: 10px 15px; 
}


li a:hover {
    color: #fff;
    text-decoration: none;
    background: rgb(255 255 255 / 12%) !important;
}
.active {
    color: #fff !important;
    /* text-decoration: none; */
    background: rgb(255 255 255 / 12%) !important;
  }



.sidebar-wrapper.closed {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: #1a2047;
    border-right: 0 solid #e4e4e400;
    z-index: 11;
    box-shadow: none !important;
    transform: translateX(-100%) !important;  /* Move out of view */
   
}
.sidebar-wrapper {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
   
    background:  #1a2047;
    /* background: 12181a; */
    border-right: 0 solid #e4e4e400;
    z-index: 11;
    box-shadow: none !important;
    
    transition: all .2s ease-out;
   
}


.semi-dark .sidebar-wrapper .metismenu a {
    color: #9ea4aa;
}

.semi-dark .sidebar-wrapper .metismenu .mm-active>a, html.semi-dark .sidebar-wrapper .metismenu a:active, html.semi-dark .sidebar-wrapper .metismenu a:focus, html.semi-dark .sidebar-wrapper .metismenu a:hover {
    color: #fff;
    text-decoration: none;
    background: rgb(255 255 255 / 12%) !important;
}
@media screen and (max-width: 1028px) {
    .mobile-toggle-menu {
        position: fixed ;
    }
}
.logo-image1 {
width: 100px;
    margin: 0px 40px;}