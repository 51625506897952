/* .content {
    margin-top: 0rem; 
    padding: 0px;
     transition: all 0.3s ease;
} */
.overlay1 {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background: #000 !important;
    opacity: .6 !important;
    z-index: 10 !important;
    display: block !important;
    cursor: move !important;
    transition: all .2s ease-out !important;
}

.customerimg-box {
    width: 50px;
    height: 50px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    position: relative;
    border-radius: 50px;
}

.centered-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}