


   .dropdown-item {

    background-color:  #ffffff;
    color: #4c5258;
  }
  
 
  .dropdown-item.hovered {
    background-color:  rgb(113 113 113 / 12%) !important;
    color: #4c5258; 
  }
  .dropdown-menu-end {
    right: 0;
    left: auto;
    top: 105%;
}
.dropdown-menu-150 {
  top: 150%;
}

.dropdown-menu-105 {
  top: 105%;
}
