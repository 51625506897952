.bg-label-blue {
    background-color: #3f51b51f;
    color: #3F51B5 !important;
}


.bg-label-info {
    background-color: #0dcaf01f !important;
    color: #0dcaf0 !important;
}
.bg-label-default {
    background-color: #ff00008a !important;
    
    color: white !important;
}

.bg-light {color: #212529 !important;
    --bs-bg-opacity: 1;
    background-color: rgb(241 241 241) !important;
}

.box-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}

.bg-light-theme {
    background-color: #ccb7ff4f !important;
    /* border-radius: 0 !important; */
    color: #aa7cff !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}
.box-border {
    border: 2px solid rgb(233, 236, 255);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}