
.body{
  letter-spacing: 0px;
    font-family: 'Ubuntu', sans-serif !important;
  background: #f7f7ff;
  overflow-x: hidden;
}
.App {
  font-family: 'Ubuntu', sans-serif !important;
}
.content {
  margin-top: 4rem;
  padding: 10px;

	transition: all 0.3s ease;
	
  }
  
  /* Styles for large screens when the menu is open */
  @media (min-width: 1280px) {
	.content-open {
	  margin-left: 250px;
	  padding: 10px; /* Adjust padding as necessary */
	}
	.content-closed {
	  margin-left: 0;
	  padding: 15px;
    
	}
  }
  .btn-label-danger {
    color: #ff4d49 !important;
    border-color: #ffe3e2 !important;
    background: #ffe3e2 !important;
}
.btn-label-primary {
  color: #666cff !important;
  border-color: #e7e7ff !important;
  background: #e7e7ff !important;
}
.order-actions button {
  font-size: 18px;
  width: 34px;
  height: 34px;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border: 1px solid #eeecec;
  text-align: center;
  border-radius: 20%;
  color: #2b2a2a;
  box-shadow: 0px 4px 8px -4px rgb(191 191 191 / 42%);
}



.bg-label-success1 {
  background-color: #e3f9ee !important;
    color: #15ca20 !important;
}
.bg-label-danger1 {
  background-color: #ffe4e4 !important;
  color: #ff4d49 !important;
}